var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.items,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Alerts "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Alleen met verzonden notificatie"},model:{value:(_vm.withCreatedNotification),callback:function ($$v) {_vm.withCreatedNotification=$$v},expression:"withCreatedNotification"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredAlerts,"options":_vm.tableSort,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] }},on:{"update:options":function($event){_vm.tableSort=$event}},scopedSlots:_vm._u([{key:"item.sleeveId",fn:function(ref){
var item = ref.item;
return [(item.references && item.references.sleeveAssignmentId)?_c('span',[_vm._v(" "+_vm._s(_vm.getSleeveId(item.references.sleeveAssignmentId))+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanizedDate(item.createdAt))+" ")]}},{key:"item.event",fn:function(ref){
var item = ref.item;
return [(item.triggeredBy === 'event' && item.triggeredById)?_c('wolk-alert-event-dialog',{attrs:{"id":item.triggeredById,"alert":item}}):_vm._e()]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.references && item.references.notificationId ? 'Ja' : 'Nee')+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }