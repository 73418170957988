<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="items"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Alerts

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />

            <v-checkbox
              v-model="withCreatedNotification"
              label="Alleen met verzonden notificatie"
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredAlerts"
            :options.sync="tableSort"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
          >
            <template #[`item.sleeveId`]="{ item }">
              <span v-if="item.references && item.references.sleeveAssignmentId">
                {{ getSleeveId(item.references.sleeveAssignmentId) }}
              </span>
            </template>

            <template #[`item.createdAt`]="{ item }">
              {{ getHumanizedDate(item.createdAt) }}
            </template>

            <template #[`item.event`]="{ item }">
              <wolk-alert-event-dialog
                v-if="item.triggeredBy === 'event' && item.triggeredById"
                :id="item.triggeredById"
                :alert="item"
              />
            </template>

            <template #[`item.sent`]="{ item }">
              {{ item.references && item.references.notificationId ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.type`]="{ item }">
              {{ item.type }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import {
  getHumanizedDate,
} from '../helpers/humanizer';

import WolkAlertEventDialog from '../components/WolkAlertEventDialog.vue';

export default {
  components: {
    WolkAlertEventDialog,
  },
  data() {
    return {
      filter: '',
      withCreatedNotification: false,
      headers: [
        { text: 'Wolknr.', value: 'sleeveId', sortable: false },
        { text: 'Aangemaakt', value: 'createdAt' },
        { text: 'Event', value: 'event', sortable: false },
        { text: 'Key', value: 'key', sortable: false },
        { text: 'Verzonden', value: 'sent', sortable: false },
      ],
      alerts: [],
      sleeves: [],
      sleeveAssignments: [],
      items: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Alerts',
          disabled: true,
          exact: true,
          to: { name: 'alerts' },
        },
      ],
      tableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      iccidRegex: /^[0-9]{19}$/g,
      sleevePublicIdRegex: /^[0-9]{6}$/g,
    };
  },
  computed: {
    filteredAlerts() {
      if (this.iccid || this.sleevePublicId) {
        return this.alerts;
      }

      return this.alerts
        .filter((n) => n._id.toLowerCase().includes(this.filter.toLowerCase()));
    },
    iccid() {
      const trimmed = this.filter.trim();

      if (!trimmed) {
        return null;
      }

      if (typeof trimmed !== 'string') {
        return null;
      }

      const matchResult = trimmed.match(this.iccidRegex);
      if (!matchResult || matchResult.length !== 1) {
        return null;
      }

      return trimmed;
    },
    sleevePublicId() {
      const trimmed = this.filter.trim();

      if (!trimmed) {
        return null;
      }

      if (typeof trimmed !== 'string') {
        return null;
      }

      const matchResult = trimmed.match(this.sleevePublicIdRegex);
      if (!matchResult || matchResult.length !== 1) {
        return null;
      }

      return trimmed;
    },
    keys() {
      const trimmed = this.filter.trim();

      const keys = [
        'wolkActivation',
        'repeatedReplaceCartridge',
        'repeatedWolkRestarts',
        'continuousReplaceCartridge',
        'nonRealisticHearbeatData',
        'batteryLow',
        'error',
        'multipleErrors',
        'memoryLost',
        'wolkNotUpdating',
      ];

      const foundKeys = [];
      keys.forEach((key) => {
        if (trimmed.includes(key)) {
          foundKeys.push(key);
        }
      });

      return foundKeys;
    },
  },
  watch: {
    iccid() {
      this.loadAlerts();
    },
    sleevePublicId() {
      this.loadAlerts();
    },
    withCreatedNotification() {
      this.loadAlerts();
    },
  },
  beforeMount() {
    this.loadAlerts();
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    loadAlerts() {
      const url = `${config.VUE_APP_API_BASE_URL}/alerts`;

      const axiosRequestConfig = {
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      };

      const query = {};
      if (this.iccid) {
        query.iccid = this.iccid;
      }

      if (this.sleevePublicId) {
        query.sleevePublicId = this.sleevePublicId;
      }

      if (this.withCreatedNotification) {
        query.onlyWithCreatedNotification = true;
      }

      if (Object.keys(query).length > 0) {
        axiosRequestConfig.params = query;
      }

      axios(axiosRequestConfig)
        .then((response) => {
          this.alerts = response.data.alerts;
          this.sleeves = response.data.sleeves;
          this.sleeveAssignments = response.data.sleeveAssignments;
        });
    },
    getSleeveId(sleeveAssignmentId) {
      if (!sleeveAssignmentId) {
        return '';
      }

      const sleeveAssignment = this.sleeveAssignments.find((sa) => sa._id === sleeveAssignmentId);
      if (!sleeveAssignment) {
        return '';
      }

      const sleeve = this.sleeves.find((s) => s._id === sleeveAssignment.sleeveId);
      if (!sleeve) {
        return '';
      }

      return sleeve.publicId;
    },
  },
};
</script>
